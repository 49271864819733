<template>
  <div class="main">
    <div class="user content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link :to="'/client/certificate'+loginAs">Certificate</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item> {{profile?.first_name}} {{profile?.last_name}}</a-breadcrumb-item>
      </a-breadcrumb>

      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <h3>Certificate</h3>
          <div class="add-outline">
            <a-button type="primary">
              <router-link :to="'/client/certificate'+loginAs"><ArrowLeftOutlined /> Back</router-link>
            </a-button>
          </div>
        </a-col>
      </a-row>
      <a-row class="certificate-Lists">
        <div class="body">
          <div class="namesec" v-if="profile">
            <img v-if="profile.profile_image" :src=profile?.profile_image />
            <img v-if="!profile.profile_image"
              :src="'https://ui-avatars.com/api/?background=6C57E2&name=' + profile?.first_name + '&rounded=true&color=ffffff'"
            />
            <span class="name">{{profile?.first_name}} {{profile?.last_name}}</span>
          </div>
          <div class="certificateTab">
            <a-list item-layout="horizontal" :data-source="certficates">
              <template #renderItem="{ item }">
                <a-list-item>
                  <a-list-item-meta>
                    <template #title>
                      {{ item.course.title }}
                    </template>
                  </a-list-item-meta>
                  <template #actions="{ index }">
                    <a-button type="link" class="date">Grade {{item.grade.toFixed(2)}}</a-button>
                    <a-button type="link" class="date">Date {{formatDate(item.date)}}</a-button>
                    <a-button type="link" class="date">Exp. Date {{formatDate(item.exp_date)}}</a-button>
                    <a-button type="primary" class="viewicon">
                      <a :href="item.certificate_url" target="_blank"><EyeOutlined /> View</a>
                    </a-button>
                    <a-button type="primary" class="downloadicon">
                      <a :href="item.certificate_url" target="_blank" download><DownloadOutlined /> Download</a>
                    </a-button>
                    <a-button type="primary" class="reloadicon" @click="reTakeConfirm(index, item.course)">
                      <ReloadOutlined /> Reasign
                    </a-button>
                    <!--<a-button type="primary" class="deleteicon" @click="deleteConfirm(index, item._id)">
                      <DeleteOutlined /> Delete
                    </a-button>-->
                  </template>
                </a-list-item>
              </template>
            </a-list>
          </div>
        </div>
      </a-row>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, createVNode } from 'vue'
import { useRoute } from 'vue-router'
import { notification, Modal } from 'ant-design-vue'

import moment from 'moment'

import certficateService from './../../../services/certficate'
import profileService from './../../../services/profile'
import courseService from '../../../services/course'

import {
  HomeOutlined,
  ReloadOutlined,
  DownloadOutlined,
  // DeleteOutlined,
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
  EyeOutlined
} from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    HomeOutlined,
    ReloadOutlined,
    DownloadOutlined,
    // DeleteOutlined,
    ArrowLeftOutlined,
    EyeOutlined
  },
  setup () {
    const route = useRoute()
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const profileId = ref<string>(route.params.id as string)
    const profile = ref()
    const certficates = ref()
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const getCandidateProfile = async (profileId) => {
      try {
        const responce = await profileService.getProfileDetails(profileId)
        profile.value = responce.data
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const getCandidateCertficate = async (profileId) => {
      try {
        const responce = await certficateService.getCertficates(profileId)
        certficates.value = responce.data
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const formatDate = (value) => {
      return moment(value).format('DD/MM/YYYY')
    }
    // const downloadCertficate = async (course, certficate, open) => {
    //   try {
    //     await certficateService.downloadCertficate(course, certficate, profile.value, open)
    //   } catch (error) {
    //     console.log('error', error)
    //     await notify('Error', error.data, 'error')
    //   }
    // }
    const reAttemptCourse = async (index, course) => {
      try {
        await courseService.reTakeCourse(course._id, profileId.value)
        certficates.value.splice(index, 1)
        notify('Success', 'Course reassigned successfully', 'success')
      } catch (error) {
        console.log('error', error)
        await notify('Error', error.data, 'error')
      }
    }
    const deleteCertificate = async (index, id) => {
      try {
        await certficateService.deleteCertficate(id)
        certficates.value.splice(index, 1)
        notify('Success', 'Certificate removed successfully', 'success')
      } catch (error) {
        console.log('error', error)
        await notify('Error', error.data, 'error')
      }
    }
    const reTakeConfirm = async (index, course) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Are you sure want to reassign the course?.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk () {
          await reAttemptCourse(index, course)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const deleteConfirm = async (index, id) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Are you sure want to remove the certficate?.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk () {
          await deleteCertificate(index, id)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    onMounted(async () => {
      getCandidateProfile(profileId.value)
      getCandidateCertficate(profileId.value)
    })
    return {
      profile,
      certficates,
      formatDate,
      // downloadCertficate,
      reTakeConfirm,
      deleteConfirm,
      loginAs
    }
  }
})
</script>

<style lang="scss">
.user {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 10px 10px;
    border-radius: 10px;
    .ant-col {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    h3 {
      font-size: 16px;
      text-transform: capitalize;
      color: #646464;
      font-family: "TT Norms Pro Medium";
      margin: 0;
    }
   .add-outline {
      float: right;
      .ant-btn {
        background-color: transparent;
        border-color: #7EAF1A;
        color: #7EAF1A;
        border-radius: 5px;
        font-family: "TT Norms Pro Medium";
        text-shadow: none;
        box-shadow: none;
        a {
          color: #7EAF1A;
        }
      }
    }
  }
  .certificate-Lists {
    padding: 10px;
    .body {
      background: #ffffff;
      box-shadow: 0px 5px 10px #00000003;
      border-radius: 8px;
      padding: 15px;
      width: 100%;
      font-family: "TT Norms Pro Medium";
      .namesec {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;
        padding: 0 0 10px 0;
        img {
          width: 45px;
          height: 45px;
          line-height: 35px;
          font-size: 20px;
          transform: scale(0.8);
          border-radius: 50%;
          margin: 0 5px 0 0;
        }
        span {
          color: #7EAF1A;
          text-transform: capitalize;
          font-family: "TT Norms Pro Bold";
          font-size: 18px;
        }
      }
      .certificateTab {
        padding: 20px 0 0 0;
        .ant-list-item {
          border-bottom: none;
          background: #f7fafc;
          border-radius: 6px;
          margin: 0px 0 15px 0;
          .ant-list-item-meta {
            width: 100%;
          }
          .ant-list-item-meta-content {
            padding: 0 370px 0 15px;
            // width: calc(100% - 300px);
            display: flex;
            h4 {
              width: 60%;
              line-height: 36px;
              margin: 0;
              color: #646464;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              text-align: left;
            }
            .ant-list-item-meta-description {
              width: 40%;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              line-height: 36px;
              color: #72cf97;
              text-align: left;
              text-transform: capitalize;
            }
          }
          .ant-list-item-action {
            position: absolute;
            right: 0;
            .ant-btn {
              border-radius: 5px;
              border: none;
              font-family: "TT Norms Pro Medium";
              color: #fff;
              box-shadow: none;
              &.viewicon {
                background: #3a9642;
              }
              &.downloadicon {
                background: #f6596f;
              }
              &.reloadicon {
                background: #ffc788;
              }
              &.deleteicon {
                background: #72cf97;
              }
            }
            .date {
              color: #646464;
            }
          }
        }
      }
    }
  }
}
</style>
