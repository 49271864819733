
import { defineComponent, ref, onMounted, createVNode } from 'vue'
import { useRoute } from 'vue-router'
import { notification, Modal } from 'ant-design-vue'

import moment from 'moment'

import certficateService from './../../../services/certficate'
import profileService from './../../../services/profile'
import courseService from '../../../services/course'

import {
  HomeOutlined,
  ReloadOutlined,
  DownloadOutlined,
  // DeleteOutlined,
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
  EyeOutlined
} from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    HomeOutlined,
    ReloadOutlined,
    DownloadOutlined,
    // DeleteOutlined,
    ArrowLeftOutlined,
    EyeOutlined
  },
  setup () {
    const route = useRoute()
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const profileId = ref<string>(route.params.id as string)
    const profile = ref()
    const certficates = ref()
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const getCandidateProfile = async (profileId) => {
      try {
        const responce = await profileService.getProfileDetails(profileId)
        profile.value = responce.data
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const getCandidateCertficate = async (profileId) => {
      try {
        const responce = await certficateService.getCertficates(profileId)
        certficates.value = responce.data
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const formatDate = (value) => {
      return moment(value).format('DD/MM/YYYY')
    }
    // const downloadCertficate = async (course, certficate, open) => {
    //   try {
    //     await certficateService.downloadCertficate(course, certficate, profile.value, open)
    //   } catch (error) {
    //     console.log('error', error)
    //     await notify('Error', error.data, 'error')
    //   }
    // }
    const reAttemptCourse = async (index, course) => {
      try {
        await courseService.reTakeCourse(course._id, profileId.value)
        certficates.value.splice(index, 1)
        notify('Success', 'Course reassigned successfully', 'success')
      } catch (error) {
        console.log('error', error)
        await notify('Error', error.data, 'error')
      }
    }
    const deleteCertificate = async (index, id) => {
      try {
        await certficateService.deleteCertficate(id)
        certficates.value.splice(index, 1)
        notify('Success', 'Certificate removed successfully', 'success')
      } catch (error) {
        console.log('error', error)
        await notify('Error', error.data, 'error')
      }
    }
    const reTakeConfirm = async (index, course) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Are you sure want to reassign the course?.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk () {
          await reAttemptCourse(index, course)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const deleteConfirm = async (index, id) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Are you sure want to remove the certficate?.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk () {
          await deleteCertificate(index, id)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    onMounted(async () => {
      getCandidateProfile(profileId.value)
      getCandidateCertficate(profileId.value)
    })
    return {
      profile,
      certficates,
      formatDate,
      // downloadCertficate,
      reTakeConfirm,
      deleteConfirm,
      loginAs
    }
  }
})
